import {
  getGlobalParamsOptionsAsync,
  getParamsOptionsAsync
} from '@/utils/common'

import Validator from '@/validators'
import i18n from '@/i18n'
import {
  phoneValidator,
  nameValidator,
  numberRangeValidator
} from '@/utils/validate'

var newPasscodeValue = ''
const pwdValidator = (rule, value, callback) => {
  newPasscodeValue = value
  callback()
}
const confirmPwdValidator = (rule, value, callback) => {
  if (value !== newPasscodeValue) {
    return callback(new Error(i18n.global.t('general.code-not-match')))
  } else {
    callback()
  }
}

export const baseFormConfig = {
  formItems: [
    {
      field: 'userName',
      type: 'input',
      label: 'user.user-name',
      otherOptions: {
        maxlength: 80
      }
    },
    {
      field: 'firstName',
      type: 'input',
      label: 'user.first-name',
      otherOptions: {
        maxlength: 50
      }
    },
    {
      field: 'middleName',
      type: 'input',
      label: 'user.middle-name',
      otherOptions: {
        maxlength: 50
      }
    },
    {
      field: 'lastName',
      type: 'input',
      label: 'user.last-name',
      otherOptions: {
        maxlength: 50
      }
    },
    {
      field: 'email',
      type: 'input',
      label: 'user.email',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'mobile',
      type: 'input',
      label: 'user.phone',
      otherOptions: {
        maxlength: 20
      }
    },
    {
      field: 'expiryDays',
      type: 'input',
      label: 'user.password-expiry',
      initialValue: '90',
      otherOptions: {
        maxlength: 3
      }
    },
    {
      field: 'address',
      type: 'input',
      label: 'user.address',
      otherOptions: {
        maxlength: 300
      }
    },
    {
      field: 'passCode',
      type: 'password',
      label: 'profile.password',
      otherOptions: {
        maxlength: 64
      }
    },
    {
      field: 'confirmPassCode',
      type: 'password',
      label: 'profile.confirm-password',
      otherOptions: {
        maxlength: 64
      }
    },
    {
      field: 'roleId',
      type: 'select',
      label: 'common.app-user-role',
      options: [],
      isLinkageOptions: true,
      isResolveGlobalParams: true,
      handler: () =>
        getGlobalParamsOptionsAsync('roleList', {
          isNeedPreFix: false
        }),
      otherOptions: {
        multiple: true
      }
    },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'file.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        isSingle: true
      },
      isShow: false,
      options: [],
      handler: () =>
        getParamsOptionsAsync('tenantList', {
          isNeedPreFix: false
        })
    }
  ],
  validateRules: {
    userName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: nameValidator, trigger: 'change' }
    ],
    firstName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: nameValidator, trigger: 'change' }
    ],
    middleName: [{ validator: nameValidator, trigger: 'change' }],
    lastName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: nameValidator, trigger: 'change' }
    ],
    email: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      {
        type: 'email',
        message: 'general.enter-email-message'
      }
    ],
    tenantId: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    expiryDays: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: numberRangeValidator(30, 90), trigger: 'change' }
    ],
    mobile: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: phoneValidator, trigger: 'change' }
    ],
    roleId: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    passCode: [
      { required: true, message: 'general.required', trigger: 'change' },
      {
        validator: Validator.regex,
        regex:
          /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[:;?.,<>()^`![|~\\@#$%&*~])).{8,20}$/,
        message: 'validator.new-password-validate'
      },
      { validator: pwdValidator, trigger: 'change' }
    ],
    confirmPassCode: [
      { required: true, message: 'general.required', trigger: 'change' },
      { validator: confirmPwdValidator, trigger: 'change' }
    ]
  }
}
