<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfigRef"
      @handleNewClick="handleSubmitClick"
      :pageTitle="pageTitle"
    ></page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/user.modal.js'
import { ref, getCurrentInstance, onMounted, onUnmounted } from 'vue'
import i18n from '@/i18n'
import { findCurrentSelectedTreeNode } from '@/utils/util.js'
import _ from 'lodash' // 导入loadsh插件
import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
import { getParamsOptions } from '@/utils/common'
import { getItem } from '@/utils/storage'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global

pageTitle.value = t('general.router-user-new')

const router = useRouter()
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
// const store = useStore()
const baseFormConfigRef = ref(baseFormConfig)
const bus = appContext.config.globalProperties.$bus

const baseFormRef = ref()

const isShowTenant = (roleIds) => {
  const tenantItem = baseFormConfigRef.value.formItems.find(
    (item) => item.field === 'tenantId'
  )
  const roleLists = getItem('roleList') || []
  const roleType = []
  if (roleIds && roleIds.length) {
    roleIds.map((id) => {
      roleLists.map((item) => {
        if (item.id === id) {
          roleType.push(item.type)
        }
      })
    })
    tenantItem.isShow = !roleType.some((item) => item === 0)
  }
  if (!tenantItem.isShow) {
    baseFormRef.value.formData.tenantId = ''
  }
}

const handleChange = async (form) => {
  const { fieldName } = form
  if (fieldName === 'roleId') {
    isShowTenant(form.roleId)
  }
}

onMounted(async () => {
  bus.on('linkageOptionsChange', handleChange)
  isShowTenant('')
})

onUnmounted(() => {
  // 组件销毁前注销事件监听
  bus.off('linkageOptionsChange', handleChange)
})

const tenantList = getParamsOptions('tenantList')

const handleSubmitClick = async (data) => {
  const form = _.cloneDeep(data)
  form.roleId = form.roleId && form.roleId.length ? form.roleId.join(',') : ''
  form.tenantId = data.tenantId
    ? findCurrentSelectedTreeNode(tenantList, data.tenantId, 'name')
    : ''
  handleMessageCommit('user/handleAddUserAction', form).then(() => {
    router.push('/user/overview')
  })
}
</script>
